var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "powerful-realtime-scheduling" },
    [
      _c(
        "div",
        { staticClass: "top-search" },
        [
          _c("scheduling-header", {
            on: { queryCurrentLine: _vm.queryCurrentLine }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "center-content",
          style: { height: "calc(100vh - 32vh - 32px - 20px - 100px)" }
        },
        [
          _c(
            "div",
            { staticClass: "center-left" },
            [
              _c(
                "div",
                { staticClass: "header-opration" },
                [
                  _c(
                    "div",
                    { staticClass: "bottons_div" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.sendMessage }
                        },
                        [_vm._v("消息下发")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.sendPlanText()
                            }
                          }
                        },
                        [_vm._v("下发行车计划")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.changeDriver("isPlan")
                            }
                          }
                        },
                        [_vm._v("调整计划")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.changeAverage }
                        },
                        [_vm._v("匀点")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.addShiftOrTrip }
                        },
                        [_vm._v("添加班次/车次")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form",
                    {
                      staticClass: "demo-form-inline",
                      attrs: {
                        inline: true,
                        model: _vm.form,
                        "label-position": "left",
                        "label-width": "90px"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "发车类型:", prop: "moveType" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择发车类型",
                                clearable: ""
                              },
                              on: { change: _vm.getCheCiListFn },
                              model: {
                                value: _vm.form.moveType,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "moveType", $$v)
                                },
                                expression: "form.moveType"
                              }
                            },
                            _vm._l(_vm.moveTypes, function(i) {
                              return _c("el-option", {
                                key: i.value,
                                attrs: { label: i.label, value: i.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "车牌号:", prop: "vehicleNo" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择车牌号",
                                clearable: ""
                              },
                              on: { change: _vm.getCheCiListFn },
                              model: {
                                value: _vm.form.vehicleNo,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "vehicleNo", $$v)
                                },
                                expression: "form.vehicleNo"
                              }
                            },
                            _vm._l(_vm.allBusByline, function(i) {
                              return _c("el-option", {
                                key: i.vehicleNo,
                                attrs: { label: i.cph, value: i.vehicleNo }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "hideBox" }, [
                    _vm.buttonFlag
                      ? _c(
                          "span",
                          { staticClass: "narrow", on: { click: _vm.narrow } },
                          [_c("i", { staticClass: "iconfont its_zhankai" })]
                        )
                      : _vm._e(),
                    !_vm.buttonFlag
                      ? _c(
                          "span",
                          {
                            staticClass: "enlarge",
                            on: { click: _vm.enlarge }
                          },
                          [_c("i", { staticClass: "iconfont its_zhedie" })]
                        )
                      : _vm._e()
                  ])
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "el-table-infinite-scroll",
                      rawName: "v-el-table-infinite-scroll",
                      value: _vm.load,
                      expression: "load"
                    }
                  ],
                  ref: "tableCheci",
                  staticClass: "basicBox dispatchMonitoring",
                  attrs: {
                    data: _vm.checiNowList,
                    stripe: "",
                    height: _vm.tableHeight,
                    "cell-style": { color: "#000", fontSize: "16px" },
                    "header-cell-style": {
                      background: "#fff",
                      color: "#999999"
                    },
                    "infinite-scroll-disabled": _vm.loading,
                    "infinite-scroll-distance": "20"
                  },
                  on: { "selection-change": _vm.getCheckedData }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "selection",
                      width: "43",
                      selectable: _vm.checkboxT,
                      disabled: "true"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "moveType",
                      label: "发车类型",
                      width: "76",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm.moveTypes.find(function(t) {
                                  return t.value === scope.row.moveType
                                }).label
                              )
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "beginTime",
                      label: "计划发车",
                      width: "76",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "leaveTime",
                      label: "实际发车",
                      width: "76",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "endTime",
                      label: "计划到达",
                      width: "76",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "arriveTime",
                      label: "实际到达",
                      width: "76",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "workTime",
                      label: "时长",
                      width: "60",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "cph", label: "车牌号", width: "110" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#01adff",
                                  cursor: "pointer"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.showScheduling(scope.row)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(scope.row.cph))]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "driverName",
                      label: "计划司机",
                      width: "76",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "realityDriverName",
                      label: "实际司机",
                      width: "76",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "busStatus",
                      label: "行车状态",
                      width: "76",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.busStatus == "0"
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#ff1212" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("filterBusStatus")(
                                          scope.row.busStatus
                                        )
                                      )
                                    )
                                  ]
                                )
                              : _vm._e(),
                            scope.row.busStatus == "1"
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#ffcf0a" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("filterBusStatus")(
                                          scope.row.busStatus
                                        )
                                      )
                                    )
                                  ]
                                )
                              : _vm._e(),
                            scope.row.busStatus == "2"
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#0053e1" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("filterBusStatus")(
                                          scope.row.busStatus
                                        )
                                      )
                                    )
                                  ]
                                )
                              : _vm._e(),
                            scope.row.busStatus == "3"
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#2bc505" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("filterBusStatus")(
                                          scope.row.busStatus
                                        )
                                      )
                                    )
                                  ]
                                )
                              : _vm._e(),
                            scope.row.busStatus == "4"
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterBusStatus")(
                                        scope.row.busStatus
                                      )
                                    )
                                  )
                                ])
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "stationDesc",
                      label: "当前位置",
                      width: "140",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "计划状态",
                      width: "76",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.adjustStatus == 0
                              ? _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("filterAdjustStatus")(
                                          scope.row.adjustStatus
                                        )
                                      ) +
                                      " "
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterAdjustStatus")(
                                        scope.row.adjustStatus
                                      )
                                    )
                                  )
                                ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "natureState",
                      label: "变更说明",
                      width: "76",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.natureState == "3" ||
                            scope.row.natureState == "4" ||
                            scope.row.natureState == "5"
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#0053e1" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        (
                                          _vm.natureOptions.find(function(t) {
                                            return (
                                              t.dictCode ===
                                              scope.row.natureState
                                            )
                                          }) || { dictValue: "" }
                                        ).dictValue
                                      )
                                    )
                                  ]
                                )
                              : _vm._e(),
                            scope.row.natureState == "6"
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#acb2bc" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        (
                                          _vm.natureOptions.find(function(t) {
                                            return (
                                              t.dictCode ===
                                              scope.row.natureState
                                            )
                                          }) || { dictValue: "" }
                                        ).dictValue
                                      )
                                    )
                                  ]
                                )
                              : _vm._e(),
                            scope.row.natureState == "7"
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#2dbff7" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        (
                                          _vm.natureOptions.find(function(t) {
                                            return (
                                              t.dictCode ===
                                              scope.row.natureState
                                            )
                                          }) || { dictValue: "" }
                                        ).dictValue
                                      )
                                    )
                                  ]
                                )
                              : _vm._e(),
                            scope.row.natureState == "0" ||
                            scope.row.natureState == "128"
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      (
                                        _vm.natureOptions.find(function(t) {
                                          return (
                                            t.dictCode === scope.row.natureState
                                          )
                                        }) || { dictValue: "" }
                                      ).dictValue
                                    )
                                  )
                                ])
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "详情" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.busStatus == 3
                              ? _c(
                                  "el-button",
                                  {
                                    style: { "font-size": "16px" },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.openDiaLog("查看", scope.row)
                                      }
                                    }
                                  },
                                  [_vm._v("查看")]
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "remark", label: "备注" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "center-right grid-content" },
            [
              _c(
                "el-tabs",
                { attrs: { type: "border-card" } },
                [
                  _c(
                    "el-tab-pane",
                    [
                      _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                        _vm._v("车辆请求("),
                        _c(
                          "span",
                          {
                            style: {
                              color:
                                _vm.requestlistNeedComprate > 0 ? "red" : ""
                            }
                          },
                          [_vm._v(_vm._s(_vm.requestlistNeedComprate))]
                        ),
                        _vm._v(")")
                      ]),
                      _c(
                        "el-table",
                        {
                          staticClass: "basicBox dispatchMonitoring",
                          attrs: {
                            data: _vm.requestlist,
                            height: _vm.heightBottom,
                            stripe: "",
                            "cell-style": { color: "#000", fontSize: "14px" },
                            "header-cell-style": {
                              background: "#FAFAFA",
                              color: "#999999"
                            }
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "cph",
                              label: "车辆",
                              "show-overflow-tooltip": "",
                              width: "92"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("div", { staticClass: "flex-style" }, [
                                      _c("i", {
                                        class: _vm.getBusIcon(
                                          scope.row.isOnline
                                        )
                                      }),
                                      _c("span", [
                                        _vm._v(_vm._s(scope.row.cph.slice(2)))
                                      ])
                                    ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "线路",
                              prop: "companyName",
                              "show-overflow-tooltip": "",
                              width: "50"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "driverName",
                              label: "司机",
                              "show-overflow-tooltip": "",
                              width: "66"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "eventId",
                              label: "消息",
                              width: "66",
                              "show-overflow-tooltip": ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _vm._v(
                                      _vm._s(
                                        (
                                          _vm.natureOptions.find(function(t) {
                                            return (
                                              t.dictCode == scope.row.eventId
                                            )
                                          }) || { dictValue: "其他" }
                                        ).dictValue
                                      )
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "insertTime",
                              label: "时间",
                              width: "76"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("filterTime")(
                                          scope.row.insertTime
                                        )
                                      )
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "opStats",
                              label: "状态",
                              width: "90"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        style: {
                                          color:
                                            scope.row.opStats == 2
                                              ? "#f74242"
                                              : ""
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("filteropStats")(
                                                scope.row.opStats
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "action",
                              label: "操作",
                              width: "90"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    scope.row.opStats !== 1 &&
                                    scope.row.opStats !== 2
                                      ? _c("div", { staticClass: "action" }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "agree",
                                              on: {
                                                click: function($event) {
                                                  return _vm.agreeAndReject(
                                                    scope.row.id,
                                                    1
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("同意")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "reject",
                                              on: {
                                                click: function($event) {
                                                  return _vm.agreeAndReject(
                                                    scope.row.id,
                                                    2
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("拒绝")]
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c("scheduling-view", {
        ref: "schedulingView",
        attrs: {
          lineCode: _vm.lineCode,
          activeName: _vm.activeName,
          containHeight: "calc(32vh + 16px)"
        },
        on: {
          sendPlanText: _vm.sendPlanText,
          showCarDetail: _vm.showCarDetail,
          sendMessage: _vm.sendMessage,
          changeDriverPop: _vm.changeDriverPop,
          manualStart: _vm.manualStart,
          changeStatus: _vm.changeStatus
        }
      }),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.title,
                visible: _vm.dialogVisible,
                width: _vm.width
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                }
              }
            },
            [
              _vm.title == "单车发班表"
                ? _c("schedulingPlan", {
                    attrs: {
                      busPlanList: _vm.busPlanList,
                      busCode: _vm.busCode,
                      banCiMoveType: _vm.banCiMoveType
                    },
                    on: {
                      closeDia: function() {
                        return (_vm.dialogVisible = false)
                      }
                    }
                  })
                : _vm._e(),
              _vm.title == "发送消息"
                ? _c("sendMsg", {
                    attrs: { lineId: _vm.lineCode },
                    on: {
                      closeDia: function() {
                        return (_vm.dialogVisible = false)
                      }
                    }
                  })
                : _vm._e(),
              _vm.title == "计划调整"
                ? _c("driverPlan", {
                    attrs: {
                      updateform: _vm.updateform,
                      nowBusByline: _vm.nowBusByline,
                      nowDriverByline: _vm.nowDriverByline,
                      isPlan: !_vm.isPlan
                    },
                    on: {
                      closeDia: function() {
                        return (_vm.dialogVisible = false)
                      }
                    }
                  })
                : _vm._e(),
              _vm.title == "添加班次/车次"
                ? _c("addShiftOrTrip", {
                    ref: "addShiftOrTripRefs",
                    on: {
                      closeDia: function() {
                        return (_vm.dialogVisible = false)
                      }
                    }
                  })
                : _vm._e(),
              _vm.title == "排班匀点"
                ? _c(
                    "div",
                    { staticClass: "form search-area" },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "averageform",
                          staticClass: "demo-form dialog-form",
                          attrs: {
                            model: _vm.averageform,
                            "status-icon": "",
                            rules: _vm.averagerules,
                            "label-position": "left",
                            "label-width": "102px"
                          }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "400px" },
                              attrs: { label: "匀点方向", prop: "direction" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择方向" },
                                  model: {
                                    value: _vm.averageform.direction,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.averageform,
                                        "direction",
                                        $$v
                                      )
                                    },
                                    expression: "averageform.direction"
                                  }
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "向前", value: "1" }
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "向后", value: "2" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "400px" },
                              attrs: { label: "匀点数", prop: "spotCount" }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.averageform.spotCount,
                                  callback: function($$v) {
                                    _vm.$set(_vm.averageform, "spotCount", $$v)
                                  },
                                  expression: "averageform.spotCount"
                                }
                              }),
                              _c("span", { staticClass: "numberNotice" }, [
                                _vm._v(
                                  "操作说明：匀点车次相邻车次的匀点数为1，以此类推匀点数递增"
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.title == "排班匀点"
                ? _c(
                    "div",
                    { staticClass: "buttonGroup text-right" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "default" },
                          on: {
                            click: function($event) {
                              _vm.dialogVisible = false
                            }
                          }
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.saveaverage }
                        },
                        [_vm._v("保存")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.title == "修改车辆状态"
                ? _c(
                    "el-form",
                    {
                      ref: "statusForm",
                      staticClass: "demo-form dialog-form",
                      attrs: {
                        model: _vm.statusForm,
                        "label-width": "102px",
                        rules: _vm.rules
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "车次状态：", prop: "state" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "选择车次状态" },
                              model: {
                                value: _vm.statusForm.state,
                                callback: function($$v) {
                                  _vm.$set(_vm.statusForm, "state", $$v)
                                },
                                expression: "statusForm.state"
                              }
                            },
                            _vm._l(_vm.natureOptions, function(item) {
                              return _c("el-option", {
                                key: item.dictCode,
                                attrs: {
                                  label: item.dictValue,
                                  value: Number(item.dictCode)
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.title == "修改车辆状态"
                ? _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer"
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "default", size: "small" },
                          on: {
                            click: function($event) {
                              _vm.dialogVisible = false
                            }
                          }
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.saveStatus }
                        },
                        [_vm._v("保存")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.title == "查看"
                ? _c(
                    "div",
                    { staticClass: "table-box" },
                    [
                      _c("div", { staticClass: "refer-header" }, [
                        _c("label", [
                          _vm._v("里程数：" + _vm._s(_vm.formDetail.runMile))
                        ]),
                        _c("label", [
                          _vm._v("趟次：" + _vm._s(_vm.formDetail.times))
                        ])
                      ]),
                      _c(
                        "el-table",
                        {
                          staticClass: "basicBox dispatchMonitoring",
                          attrs: {
                            stripe: "",
                            data: _vm.referTable,
                            "cell-style": { color: "#000", fontSize: "16px" },
                            "header-cell-style": {
                              background: "#FAFAFA",
                              color: "#999999"
                            }
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { prop: "serialNo", label: "序号" }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "stationName",
                              "show-overflow-tooltip": "",
                              label: "站点名称"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "arriveTime", label: "到达时间" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.arriveTime
                                              ? scope.row.arriveTime.slice(
                                                  11,
                                                  16
                                                )
                                              : ""
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1736270668
                            )
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "leaveTime", label: "发车时间" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.leaveTime
                                              ? scope.row.leaveTime.slice(
                                                  11,
                                                  16
                                                )
                                              : ""
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              583280300
                            )
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "stopoverSecond", label: "停留时间" }
                          }),
                          _vm._v(" >")
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.dialogTableVisible
        ? _c("car-dialog", {
            attrs: {
              titlex: _vm.plate,
              id: _vm.plate,
              checkObj: _vm.carDetailObj,
              Upgrades: true
            },
            on: {
              dialogVisible: function() {
                return (_vm.dialogTableVisible = false)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }