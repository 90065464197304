<template>
  <!-- 公交2.0 实时调度--新需求页面 -->
  <div class="powerful-realtime-scheduling">
    <!-- 顶部搜索框（关注线路） -->
    <div class="top-search">
      <scheduling-header
        @queryCurrentLine="queryCurrentLine"
      ></scheduling-header>
    </div>
    <!-- 中间数据表 -->
    <!-- 100vh - 电子地图 - 顶部 - 间距 - 项目的头部 -->
    <div
      class="center-content"
      :style="{ height: 'calc(100vh - 32vh - 32px - 20px - 100px)' }"
    >
      <div class="center-left">
        <div class="header-opration">
          <div class="bottons_div">
            <el-button
              type="primary"
              size="small"
              class="btn"
              @click="sendMessage"
              >消息下发</el-button
            >
            <el-button
              type="primary"
              size="small"
              class="btn"
              @click="sendPlanText()"
              >下发行车计划</el-button
            >
            <el-button
              type="primary"
              size="small"
              class="btn"
              @click="changeDriver('isPlan')"
              >调整计划</el-button
            >
            <el-button
              type="primary"
              size="small"
              class="btn"
              @click="changeAverage"
              >匀点</el-button
            >
            <el-button
              size="small"
              type="primary"
              class="btn"
              @click="addShiftOrTrip"
              >添加班次/车次</el-button
            >
          </div>
          <el-form
            :inline="true"
            :model="form"
            label-position="left"
            class="demo-form-inline"
            label-width="90px"
          >
            <el-form-item label="发车类型:" prop="moveType">
              <el-select
                v-model="form.moveType"
                placeholder="请选择发车类型"
                @change="getCheCiListFn"
                clearable
              >
                <el-option
                  v-for="i in moveTypes"
                  :key="i.value"
                  :label="i.label"
                  :value="i.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="车牌号:" prop="vehicleNo">
              <el-select
                v-model="form.vehicleNo"
                placeholder="请选择车牌号"
                @change="getCheCiListFn"
                clearable
              >
                <el-option
                  v-for="i in allBusByline"
                  :key="i.vehicleNo"
                  :label="i.cph"
                  :value="i.vehicleNo"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <!-- 开展收起图标 -->
          <div class="hideBox">
            <span class="narrow" v-if="buttonFlag" @click="narrow">
              <i class="iconfont its_zhankai" />
            </span>
            <span class="enlarge" v-if="!buttonFlag" @click="enlarge">
              <i class="iconfont its_zhedie" />
            </span>
          </div>
        </div>
        <el-table
          class="basicBox dispatchMonitoring"
          :data="checiNowList"
          @selection-change="getCheckedData"
          stripe
          :height="tableHeight"
          :cell-style="{ color: '#000', fontSize: '16px' }"
          :header-cell-style="{ background: '#fff', color: '#999999' }"
          v-el-table-infinite-scroll="load"
          :infinite-scroll-disabled="loading"
          infinite-scroll-distance="20"
          ref="tableCheci"
        >
          <el-table-column
            type="selection"
            width="43"
            :selectable="checkboxT"
            disabled="true"
          ></el-table-column>
          <el-table-column prop="moveType" label="发车类型" width="76" show-overflow-tooltip>
            <template slot-scope="scope">{{
              moveTypes.find((t) => t.value === scope.row.moveType).label
            }}</template>
          </el-table-column>
          <el-table-column prop="beginTime" label="计划发车" width="76" show-overflow-tooltip></el-table-column>
          <el-table-column prop="leaveTime" label="实际发车" width="76" show-overflow-tooltip></el-table-column>
          <el-table-column prop="endTime" label="计划到达" width="76" show-overflow-tooltip></el-table-column>
          <el-table-column prop="arriveTime" label="实际到达" width="76" show-overflow-tooltip></el-table-column>
          <el-table-column prop="workTime" label="时长" width="60" show-overflow-tooltip></el-table-column>
          <el-table-column prop="cph" label="车牌号" width="110">
            <template slot-scope="scope">
              <span
                style="color: #01adff; cursor: pointer"
                @click="showScheduling(scope.row)"
                >{{ scope.row.cph }}</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="driverName" label="计划司机" width="76" show-overflow-tooltip></el-table-column>
          <el-table-column
            prop="realityDriverName"
            label="实际司机"
            width="76" show-overflow-tooltip
          ></el-table-column>
          <el-table-column prop="busStatus" label="行车状态" width="76" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.busStatus == '0'" style="color: #ff1212">{{
                scope.row.busStatus | filterBusStatus
              }}</span>
              <span v-if="scope.row.busStatus == '1'" style="color: #ffcf0a">{{
                scope.row.busStatus | filterBusStatus
              }}</span>
              <span v-if="scope.row.busStatus == '2'" style="color: #0053e1">{{
                scope.row.busStatus | filterBusStatus
              }}</span>
              <span v-if="scope.row.busStatus == '3'" style="color: #2bc505">{{
                scope.row.busStatus | filterBusStatus
              }}</span>
              <span v-if="scope.row.busStatus == '4'">{{
                scope.row.busStatus | filterBusStatus
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="stationDesc"
            label="当前位置"
            width="140"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="计划状态" width="76" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.adjustStatus == 0" style="color: red">
                {{ scope.row.adjustStatus | filterAdjustStatus }}
              </span>
              <span v-else>{{
                scope.row.adjustStatus | filterAdjustStatus
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="natureState" label="变更说明" width="76" show-overflow-tooltip>
            <template slot-scope="scope">
              <span
                v-if="
                  scope.row.natureState == '3' ||
                  scope.row.natureState == '4' ||
                  scope.row.natureState == '5'
                "
                style="color: #0053e1"
                >{{
                  (
                    natureOptions.find(
                      (t) => t.dictCode === scope.row.natureState
                    ) || { dictValue: '' }
                  ).dictValue
                }}</span
              >
              <span
                v-if="scope.row.natureState == '6'"
                style="color: #acb2bc"
                >{{
                  (
                    natureOptions.find(
                      (t) => t.dictCode === scope.row.natureState
                    ) || { dictValue: '' }
                  ).dictValue
                }}</span
              >
              <span
                v-if="scope.row.natureState == '7'"
                style="color: #2dbff7"
                >{{
                  (
                    natureOptions.find(
                      (t) => t.dictCode === scope.row.natureState
                    ) || { dictValue: '' }
                  ).dictValue
                }}</span
              >
              <span
                v-if="
                  scope.row.natureState == '0' || scope.row.natureState == '128'
                "
                >{{
                  (
                    natureOptions.find(
                      (t) => t.dictCode === scope.row.natureState
                    ) || { dictValue: '' }
                  ).dictValue
                }}</span
              >
            </template>
          </el-table-column>          
          <el-table-column label="详情">
            <template slot-scope="scope">
              <el-button
                type="text"
                :style="{ 'font-size': '16px' }"
                @click="openDiaLog('查看', scope.row)"
                v-if="scope.row.busStatus == 3"
                >查看</el-button
              >
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="备注"></el-table-column>
        </el-table>
      </div>
      <div class="center-right grid-content">
        <el-tabs type="border-card">
          <el-tab-pane>
            <span slot="label"
              >车辆请求(<span
                :style="{ color: requestlistNeedComprate > 0 ? 'red' : '' }"
                >{{ requestlistNeedComprate }}</span
              >)</span
            >
            <el-table
              class="basicBox dispatchMonitoring"
              :data="requestlist"
              :height="heightBottom"
              stripe
              :cell-style="{ color: '#000', fontSize: '14px' }"
              :header-cell-style="{ background: '#FAFAFA', color: '#999999' }"
            >
              <el-table-column
                prop="cph"
                label="车辆"
                show-overflow-tooltip
                width="92"
              >
                <template slot-scope="scope">
                  <div class="flex-style">
                    <i :class="getBusIcon(scope.row.isOnline)"></i>
                    <span>{{ scope.row.cph.slice(2) }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="线路"
                prop="companyName"
                show-overflow-tooltip
                width="50"
              ></el-table-column>
              <el-table-column
                prop="driverName"
                label="司机"
                show-overflow-tooltip
                width="66"
              ></el-table-column>
              <el-table-column
                prop="eventId"
                label="消息"
                width="66"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  (
                    natureOptions.find(
                      (t) => t.dictCode == scope.row.eventId
                    ) || { dictValue: '其他' }
                  ).dictValue
                }}</template>
              </el-table-column>
              <el-table-column prop="insertTime" label="时间" width="76">
                <template slot-scope="scope">{{
                  scope.row.insertTime | filterTime
                }}</template>
              </el-table-column>
              <el-table-column prop="opStats" label="状态" width="90">
                <template slot-scope="scope">
                  <span
                    :style="{
                      color: scope.row.opStats == 2 ? '#f74242' : '',
                    }"
                  >
                    {{ scope.row.opStats | filteropStats }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="action" label="操作" width="90">
                <template slot-scope="scope">
                  <div
                    class="action"
                    v-if="scope.row.opStats !== 1 && scope.row.opStats !== 2"
                  >
                    <span @click="agreeAndReject(scope.row.id, 1)" class="agree"
                      >同意</span
                    >
                    <span
                      @click="agreeAndReject(scope.row.id, 2)"
                      class="reject"
                      >拒绝</span
                    >
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- 模拟地图 -->
    <scheduling-view
      :lineCode="lineCode"
      :activeName="activeName"
      @sendPlanText="sendPlanText"
      @showCarDetail="showCarDetail"
      @sendMessage="sendMessage"
      @changeDriverPop="changeDriverPop"
      @manualStart="manualStart"
      @changeStatus="changeStatus"
      containHeight="calc(32vh + 16px)"
      ref="schedulingView"
    />
    <!-- 各种弹窗 -->
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      :width="width"
    >
      <schedulingPlan
        v-if="title == '单车发班表'"
        :busPlanList="busPlanList"
        :busCode="busCode"
        :banCiMoveType="banCiMoveType"
        @closeDia="() => (dialogVisible = false)"
      ></schedulingPlan>
      <sendMsg
        v-if="title == '发送消息'"
        :lineId="lineCode"
        @closeDia="() => (dialogVisible = false)"
      ></sendMsg>
      <driverPlan
        v-if="title == '计划调整'"
        :updateform="updateform"
        :nowBusByline="nowBusByline"
        :nowDriverByline="nowDriverByline"
        :isPlan="!isPlan"
        @closeDia="() => (dialogVisible = false)"
      ></driverPlan>
      <addShiftOrTrip
        v-if="title == '添加班次/车次'"
        ref="addShiftOrTripRefs"
        @closeDia="() => (dialogVisible = false)"
      ></addShiftOrTrip>
      <div class="form search-area" v-if="title == '排班匀点'">
        <el-form
          :model="averageform"
          status-icon
          :rules="averagerules"
          label-position="left"
          ref="averageform"
          label-width="102px"
          class="demo-form dialog-form"
        >
          <el-form-item label="匀点方向" prop="direction" style="width: 400px">
            <el-select v-model="averageform.direction" placeholder="请选择方向">
              <el-option label="向前" value="1"></el-option>
              <el-option label="向后" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="匀点数" prop="spotCount" style="width: 400px">
            <el-input v-model="averageform.spotCount"></el-input>
            <span class="numberNotice"
              >操作说明：匀点车次相邻车次的匀点数为1，以此类推匀点数递增</span
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="buttonGroup text-right" v-if="title == '排班匀点'">
        <el-button size="small" type="default" @click="dialogVisible = false"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="saveaverage"
          >保存</el-button
        >
      </div>
      <!-- 修改车辆状态 -->
      <el-form
        :model="statusForm"
        ref="statusForm"
        label-width="102px"
        :rules="rules"
        class="demo-form dialog-form"
        v-if="title == '修改车辆状态'"
      >
        <el-form-item label="车次状态：" prop="state">
          <el-select v-model="statusForm.state" placeholder="选择车次状态">
            <el-option
              v-for="item in natureOptions"
              :label="item.dictValue"
              :value="Number(item.dictCode)"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer" v-if="title == '修改车辆状态'">
        <el-button type="default" size="small" @click="dialogVisible = false"
          >取消</el-button
        >
        <el-button type="primary" size="small" @click="saveStatus"
          >保存</el-button
        >
      </span>
      <div v-if="title == '查看'" class="table-box">
        <div class="refer-header">
          <label>里程数：{{ formDetail.runMile }}</label>
          <label>趟次：{{ formDetail.times }}</label>
        </div>
        <el-table
          stripe
          :data="referTable"
          :cell-style="{ color: '#000', fontSize: '16px' }"
          :header-cell-style="{ background: '#FAFAFA', color: '#999999' }"
          class="basicBox dispatchMonitoring"
        >
          <el-table-column prop="serialNo" label="序号"></el-table-column>
          <el-table-column
            prop="stationName"
            show-overflow-tooltip
            label="站点名称"
          ></el-table-column>
          <el-table-column prop="arriveTime" label="到达时间">
            <template slot-scope="scope">
              {{
                scope.row.arriveTime ? scope.row.arriveTime.slice(11, 16) : ''
              }}
            </template>
          </el-table-column>
          <el-table-column prop="leaveTime" label="发车时间">
            <template slot-scope="scope">
              {{ scope.row.leaveTime ? scope.row.leaveTime.slice(11, 16) : '' }}
            </template>
          </el-table-column>
          <el-table-column
            prop="stopoverSecond"
            label="停留时间"
          ></el-table-column>
          ></el-table
        >
      </div>
    </el-dialog>
    <!-- 车辆详情面板 -->
    <car-dialog
      v-if="dialogTableVisible"
      :titlex="plate"
      :id="plate"
      :checkObj="carDetailObj"
      :Upgrades="true"
      @dialogVisible="() => (dialogTableVisible = false)"
    ></car-dialog>
  </div>
</template>
<script>
import {
  queryNewEventsAPI,
  getCheCiPlatform,
  queryCompleteCheCiArriveDetailAPI,
  getSamelineAllbus,
  queryBusPlan,
  sendPlanText,
  queryBusCardAPI,
  averageSpot,
  manualSendVehicle,
  manualModifyEvent,
  queryRecordArriveList,
  queryCheCiRunMileAPI,
} from "@/api/lib/bus-api.js";
import schedulingHeader from "./components/schedulingHeader.vue";
import SchedulingView from "./components/schedulingView.vue";
import { updateEventReport } from "@/api/lib/refreshAlarm.js";
import { queryDictsByCodes } from "@/api/lib/api.js";
import { formatHour } from "@/common/utils/index.js";
import elTableInfiniteScroll from "el-table-infinite-scroll";
import { formatDay } from "@/common/utils/index";
import schedulingPlan from "./components/schedulingPlan";
import sendMsg from "./components/sendMessage";
import dialog from "./components/dialog";
import driverPlan from "./components/driverPlan";
import addShiftOrTrip from "./components/addShiftOrTrip";
export default {
  directives: {
    "el-table-infinite-scroll": elTableInfiniteScroll
  },
  components: {
    schedulingHeader,
    SchedulingView,
    schedulingPlan,
    sendMsg,
    "car-dialog": dialog,
    driverPlan,
    addShiftOrTrip
  },
  data () {
    return {
      activeName: "",
      lineCode: 0,
      requestlistNeedComprate: 0,//车辆请求数据含需要处理的请求
      requestlist: [], //车辆请求列表
      timer_event: null,
      natureOptions: [],
      heightBottom: "calc(100vh - 32vh - 202px)",//
      checiNowList: [],
      form: {
        moveType: "",
        vehicleNo: "",
      },
      moveTypes: [
        {
          label: "全部",
          value: null
        },
        {
          label: "上行",
          value: 1
        },
        {
          label: "下行",
          value: 2
        }
      ],
      allBusByline: [],
      loading: false,
      checiAllList: [],
      dialogVisible: false,
      width: "30%",
      title: "",
      busPlanList: [],
      busCode: "",
      banCiMoveType: "",
      buttonFlag: false,
      tableHeight: 'calc(100vh - 32vh - 32px - 20px - 100px - 40px)',
      updateform: {
        lineId: null,
        oldCredentialsNo: null,
        oldVehicleNo: null,
        newCredentialsNo: null,
        newVehicleNo: null,
        workDay: null,
        classType: "1",
        updateType: null,
        type: "1",
        beginTime: null,
        natureState: null,
        remark: null,
        cheCiIds: [],
        moveType: ""
      },
      plate: "",
      carDetailObj: {},
      dialogTableVisible: false,
      nowDriverByline: [],
      nowBusByline: [],
      checkboxFlag: false,
      averageform: {
        direction: "2",
        cheCiIdSelected: null,
        spotCount: null
      },
      averagerules: {
        spotCount: [
          { required: true, message: "不能为空", trigger: "change" },
          {
            validator (rule, value, callback) {
              if (/(^[1-9]\d*$)/.test(value)) {
                callback();
              } else {
                callback(new Error("请输入正整数"));
              }
            },
            trigger: "blur"
          }
        ],
        cheCiIdSelected: [
          { required: true, message: "不能为空", trigger: "change" }
        ],
        direction: [{ required: true, message: "不能为空", trigger: "change" }]
      },
      statusForm: {
        state: ""
      },
      rules: {
        state: [{ required: true, message: "不能为空", trigger: "change" }]
      },
      changeStatusVehicleNo: "",
      formDetail: {},
      referTable: [],
      isPlan:false,//是否是修改未来计划
    }
  },
  mounted () {
    this.getDicts(); //字典
  },
  filters: {
    filterAdjustStatus (item) {
      return item == 0 ? "删除" : "正常";
    },
    filteropStats (item) {
      if (item == 0) {
        return "未处理";
      } else if (item == 1) {
        return "同意";
      } else if (item == 2) {
        return "拒绝";
      } else if (item == 3) {
        return "未处理(已阅)";
      }
    },
    filterBusStatus (item) {
      if (item == 0) {
        return "未收到";
      } else if (item == 1) {
        return "已收到";
      } else if (item == 2) {
        return "已发车";
      } else if (item == 3) {
        return "已完成";
      } else {
        return "无状态";
      }
    },
    filterTime (value) {
      let str = "";
      if (value) str = formatHour(value);
      return str
    }
  },
  watch: {
    lineCode (n, o) {
      this.getqueryNewEventsList(); //车辆请求-定时请求--不跟随线路改变而改变
      this.getCheCiListFn();//车次列表
      this.getSamelineAllbus();//根据线路查询车辆
    }
  },
  methods: {
    openDiaLog (title, row) {
      this.title = title;
      this.width = "600px";
      //请求接口方式
      queryCheCiRunMileAPI({cheCiId:row.id}).then(res=>{
        this.formDetail.runMile = res.data || 0;
      })
      console.log("row",row);      
      this.formDetail.times = 0.5;//固定
      //queryCompleteCheCiArriveDetailAPI  queryRecordArriveList recordId
      queryCompleteCheCiArriveDetailAPI({ cheCiId: row.id }).then(res => {
        if (res.code == "1000") {
          this.dialogVisible = true;
          this.referTable = res.data || [];
        }
      });
    },
    narrow () {
      this.buttonFlag = !this.buttonFlag;
      this.tableHeight = 'calc(100vh - 32vh - 32px - 20px - 100px - 40px)';
    },
    enlarge () {
      this.buttonFlag = !this.buttonFlag;
      this.tableHeight = 'calc(100vh - 20px - 100px - 20px - 30px)';
    },
    // 打开单车发班表弹窗
    showScheduling (row) {
      let self = this;
      let data = {
        vehicleNo: row.vehicleNo,
        planType: 2,
        workDay: formatDay(new Date()),
      };
      queryBusPlan(data)
        .then(res => {
          self.busPlanList = [];
          self.busCode = res.data.cph;
          self.banCiMoveType = res.data.banCiMoveType;
          // self.busPlanList = res.data.planList;
          res.data.planList.forEach(item => {
            if (!(item.down == "" && item.up == "")) {
              self.busPlanList.push(item);
            }
          });
          this.title = "单车发班表";
          this.width = "30%";
          self.dialogVisible = true;
        })
        .catch(error => { });
    },
    //禁用计划状态为删除的复选框
    checkboxT (row, index) {
      if (row.adjustStatus === "删除") {
        return false;
      } else {
        return true;
      }
    },
    // 选中checkbox
    getCheckedData (val) {
      if (val.length == 1) {
        this.updateform.oldCredentialsNo = val[0].credentialsNo;
        this.updateform.oldVehicleNo = val[0].vehicleNo;
        this.updateform.beginTime = val[0].beginTime;
        this.nowBusByline = [{ cph: val[0].cph, vehicleNo: val[0].vehicleNo }];
        this.nowDriverByline = [
          { driverName: val[0].driverName, credentialsNo: val[0].credentialsNo }
        ];
      }
      this.updateform.cheCiIds = val.map(ele => ele.id);
      this.checkboxFlag = true;//是底部table触发的多选数据
    },
    load () {
      if (this.checiAllList.length > 0) {
        clearInterval(this.cheTimer);
        this.checiNowList = [
          ...this.checiNowList,
          ...this.checiAllList.splice(0, 30)
        ];
      } else {
        this.loading = false;
      }
    },
    //根据线路查询车辆
    getSamelineAllbus () {
      this.form.vehicleNo = null;
      this.form.moveType = null;
      getSamelineAllbus({
        companyId: this.lineCode,
        isArranging: 1,
        workTime: formatDay(new Date()),
      }).then(res => {
        this.allBusByline = res.data;
      });
    },
    // 显示车次列表
    getCheCiListFn () {
      var self = this;
      var data = {
        companyId: this.lineCode,
        workTime: formatDay(new Date()),
        isArranging: 0,
        moveType: self.form.moveType,
        vehicleNo: self.form.vehicleNo
      };
      getCheCiPlatform(data).then(res => {
        self.checiNowList = res.data.splice(0, 20);
        self.checiAllList = res.data;
      });
    },
    //车辆状态图标
    getBusIcon (status) {
      if (status) {
        return "bus-norm bus-icon";
      } else {
        return "bus-offline bus-icon";
      }
    },
    queryCurrentLine (obj) {
      this.lineCode = obj.companyId;
      this.activeName = obj.companyName;
    },
    sendPlanText (id) {
      console.log("id", id);
      if (this.updateform.cheCiIds.length > 0 || id) {
        this.$confirm("车辆将收到最新的行车计划，是否下发?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            sendPlanText({ checiIds: id ? [id] : this.updateform.cheCiIds }).then(res => {
              if (res.code === 1000) {
                this.$message.success("操作成功");
              } else {
                this.$message.error(res.msg);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消下发行车计划"
            });
          });
      } else {
        this.$message.warning("请至少勾选1个点进行下发行车计划！");
      }
    },
    // 点击车牌号显示弹窗
    showCarDetail (vehicleNo) {
      queryBusCardAPI({ vehicleNo: vehicleNo, companyId: this.lineCode }).then(res => {
        if (res.data) {
          this.plate = res.data.cph;
          this.carDetailObj = res.data;
          this.dialogTableVisible = true;
        }
      });
      this.dialogTableVisible = true;
    },
    sendMessage () {
      this.dialogVisible = true;
      this.title = "发送消息";
      this.width = "50%";
    },
    changeDriverPop (val) {
      //清空列表table用户选中数据
      this.$refs.tableCheci.clearSelection();
      this.updateform.oldCredentialsNo = val.credentialsNo;
      this.updateform.oldVehicleNo = val.vehicleNo;
      this.updateform.beginTime = val.beginTime;
      this.nowBusByline = [{ cph: val.cph, vehicleNo: val.vehicleNo }];
      this.nowDriverByline = [
        { driverName: val.realityDriverName, credentialsNo: val.credentialsNo }
      ];
      this.updateform.cheCiIds = [val.id];
      this.changeDriver();
    },
    //手动发车
    manualStart (item) {
      const h = this.$createElement;
      this.$msgbox({
        title: "手动发车",
        type: "warning",
        message: h("p", null, [h("span", null, "确定手动发车吗 ？")]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
        .then(() => {
          manualSendVehicle({
            vehicleNo: item.vehicleNo,
            moveType: item.moveType
          }).then(res => {
            if (res.code === 1000) {
              this.$message.success(res.msg);
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            showClose: true,
            message: "已取消手动发车"
          });
        });
    },
    //修改状态
    changeStatus (item) {
      this.changeStatusVehicleNo = item.vehicleNo;
      this.dialogVisible = true;
      this.title = "修改车辆状态";
      this.width = "50%";
    },
    //保存修改状态
    saveStatus () {
      this.$refs.statusForm.validate(valid => {
        if (valid) {
          const data = {
            eventId: this.statusForm.state,
            vehicleNo: this.changeStatusVehicleNo
          };
          manualModifyEvent(data)
            .then(res => {
              if (res.code === 1000) {
                this.changeStatusVisible = false;
                this.$message.success(res.msg);
                //刷新页面
                this.$refs.schedulingView.refreshView();
              } else {
                this.$message.warning(res.msg);
              }
            })
            .catch(err => {
              console.log(err);
            });
        }
      });
    },
    // 保存匀点
    saveaverage () {
      this.$refs.averageform.validate(valid => {
        if (valid) {
          let data = { ... this.averageform };
          data.moveType = this.updateform.moveType;//上下行
          averageSpot(data)
            .then(res => {
              if (res.code === 1000) {
                this.$message.success("操作成功");
                this.averagdialogVisible = false;
                //刷新页面
                this.$refs.schedulingView.refreshView();
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch(error => {
              console.log(error);
            });
        }
      });
    },
    // 打开调整计划弹窗
    changeDriver (val) {      
      if (val && !this.checkboxFlag) {
        this.$message.warning("请至少勾选1个点进行调整计划！");
        return;
      } else {
        if (!this.updateform.cheCiIds.length) {
          //是底部table触发的多选数据
          this.$message.warning("请至少勾选1个点进行调整计划！");
          return;
        }
      }
      if (this.updateform.cheCiIds.length > 1) {
        this.updateform.type = "3";
      } else if (this.updateform.cheCiIds.length === 1) {
        this.updateform.type = "1";
      }
      this.isPlan = val==='isPlan';//是否是修改未来计划
      console.log("-------", this.updateform.oldVehicleNo);
      this.updateform.lineId = this.editableTabsValue * 1;
      this.updateform.updateType = 0;
      this.updateform.newCredentialsNo = null;
      this.updateform.newVehicleNo = null;
      this.title = "计划调整";
      this.dialogVisible = true;
      this.width = "50%";
      
    },
    // 打开匀点弹窗
    changeAverage () {
      if (this.updateform.cheCiIds.length == 1) {
        this.dialogVisible = true;
        this.title = "排班匀点";
        this.width = "50%";
        this.averageform.cheCiIdSelected = this.updateform.cheCiIds[0];
      } else {
        this.$message.warning("请勾选1个点进行匀点！");
      }
    },
    addShiftOrTrip () {
      this.title = "添加班次/车次";
      this.dialogVisible = true;
      this.width = "50%";
    },
    //车辆请求
    getqueryNewEventsList () {
      if (this.timer_event) clearInterval(this.timer_event);
      const intervalIimerFn = () => {
        // queryNewEventsList
        queryNewEventsAPI()
          .then(res => {
            this.requestlist = res.data || [];
            let num = 0;
            //计算当前数据的
            this.requestlist.forEach(item => {
              if (item.opStats !== 1 && item.opStats !== 2) num++;
            })
            this.requestlistNeedComprate = num;
          })
          .catch(err => {
            console.log(err);
          });
      };
      intervalIimerFn();
      this.timer_event = setInterval(() => {
        intervalIimerFn();
      }, 30000);
    },
    //车辆请求同意和拒绝操作
    agreeAndReject (id, opStats) {
      updateEventReport({ id, opStats })
        .then(res => {
          if (res.code === 1000) {
            this.$message.success("修改成功");
            this.getqueryNewEventsList();
          } else {
            this.$message.warning(res.msg);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    //获取字典值
    getDicts () {
      queryDictsByCodes({ parentCodes: "BUSLX" }).then(res => {
        if (res.code === 1000) {
          this.natureOptions = res.data.BUSLX;
        }
      });
    },
  },
  destroyed () {
    clearInterval(this.timer_event);
  }
}
</script>
<style lang="scss" scoped>
.powerful-realtime-scheduling {
  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  font-size: 14px;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  @include themify() {
    .hideBox {
      width: 60px;
      span {
        width: 74px;
        height: 24px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        background-size: 5rem;
        background-position: center;
        background-repeat: no-repeat;
        color: themed('b4');
        border-color: themed('b3');
        border-radius: 6px 6px 0px 0px;
        cursor: pointer;
      }
    }
  }
  .center-content {
    display: flex;
    justify-content: space-between;
    .center-left {
      width: calc(100% - 470px);
      position: relative;
      top: 0;
      z-index: 2000;
      background: #f7f8f9;
      margin-right: 10px;
      .header-opration {
        display: flex;
        align-items: center;
        justify-content: space-between;
        /deep/.el-form-item {
          margin-bottom: 0;
        }
        .bottons_div {
        }
      }
    }
    .center-right {
      width: 460px; //526
      /deep/ .el-tabs--border-card > .el-tabs__content {
        padding: 0;
      }
    }
    //车辆请求样式

    .grid-content {
      .basicBox {
        margin-top: 10px;
      }
      .action {
        // display: flex;
        .agree {
          cursor: pointer;
          color: #336ffe;
        }
        .reject {
          cursor: pointer;
          color: #f74242;
          margin-left: 10px;
        }
      }
      .flex-style {
        display: flex;
        align-items: center;
      }
    }
    .bus-icon {
      display: inline-block;
      content: '';
      height: 20px;
      width: 20px;
    }
    .bus-norm {
      background: url('../../../../assets/images/busIcon/bus_map_nor.png')
        no-repeat;
      background-size: 100%;
    }

    .bus-offline {
      background: url('../../../../assets/images/busIcon/bus_map_leave.png')
        no-repeat;
      background-size: 100%;
    }
    .table-box {
    padding: 20px;
    box-sizing: border-box;
    .refer-header {
      display: flex;
      justify-content: space-between;
      padding: 20px;
      padding-top: 0;
    }
  }
  }
}
</style>